@font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: local('Lexend Deca Regular'), local('LexendDeca-Regular'), url(https://fonts.gstatic.com/s/lexenddeca/v1/K2F1fZFYk-dHSE0UPPuwQ5qnJy_YZ2ON.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    background: #0c6b2a;
}
.App {
    color: white;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 18px;
}
header {
    padding: 15px 15px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#logo {

}
#logo img {
    width: 45px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    /*animation: hueshift 10s infinite linear;*/
}

h1 {
    font-size: 40px;
    color: white;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}
a {
    color: #1DB954;
}
nav {
    vertical-align: middle;
}
nav a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}
nav a:hover {
    text-decoration: underline;
}
nav button {
    margin-left: 15px;
}

.splash {
    text-align: center;
    margin-bottom: 50px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.splashHeader {
    margin: 10px auto 20px;
}
.splash h2 {
    margin-bottom: 14px;
    font-size: 35px;
}
.splash p {
    margin-bottom: 20px;
    opacity: 0.8;
}
.background {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: #111;
}
img.background {
    object-fit: cover;
    opacity: 0.4;
    animation: hueshift 10s infinite linear;
}
.exampleRow {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow-x: scroll;
}
.exampleRow img {
    width: 100vw;
}

@keyframes hueshift {
    0% {
        filter: hue-rotate(0deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}

.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 80vh;
}
.Login input {
    margin: 10px 0;
}

.textBlock {
    padding: 20px;
    color: white;
}

article {
    text-align: center;
    margin: 0 auto;
}
.steps {
    margin: 15px;
    font-size: 20px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.step {
    text-align: left;
    display: inline-block;
    max-width: 4in;
    height: 100%;
    vertical-align: top;
    padding: 20px;
    margin: 5px;
    border-radius: 15px;
    background: #1DB954;
}
.step h3 {
    font-size: 45px;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    background: white;
    color: #222;
    text-align: center;
    vertical-align: top;
    margin: 0;
    float: left;
    margin-right: 10px;
}
.step p {
    vertical-align: top;
    margin-top: 0;
}
.step h2 {
    margin-top: 8px;
}
.step img {
    max-width: 100%;
    border-radius: 10px;
    margin-top: 8px;
}
.step a {
    color: white;
}
.steps button {
    margin: 5px;
    padding:
}
.uploader {
    margin-top: 5px;
}
.uploader label {
    border-radius: 10px;
    padding: 5px;
    display: inline-block;
    cursor: pointer;
}
.uploader label p {
    display: block;
    margin: 0;
    padding: 20px;
    border-radius: 10px;
    border: dashed 3px white;
}
.uploader svg {
    margin-right: 5px;
}

.viewer {

}
.viewer.example {
    opacity: 0.7;
    filter: contrast(1.18);
}
.options {
    padding: 15px 15px 10px;
}
.viewer.example .options {
    display: none;
}
.options .column {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}
.column:last-child {
    margin-right: 0;
}
.option {
    margin-bottom: 10px;
}
.option-group {
    border: 2px solid #1DB954;
    border-radius: 6px;
    margin-bottom: 10px;
}
.option-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #1DB954;
    cursor: pointer;
}
.option-group-header h3 {
    margin: 0 7px;
    font-size: 18px;
}
.option-group-content {
    border-top: 2px solid #1DB954;
    padding: 10px;
}
input {
    outline: 0;
    background: none;
    border: none;
    border-bottom: 3px solid #1DB954;
}
button, a.button, input[type='submit'] {
    display: inline-block;
    background: #1DB954;
    color: white;
    text-decoration: none;
    padding: 12px 15px;
    border: none;
    font: inherit;
    border-radius: 50px;
    cursor: pointer;
}
.button.big {
    padding: 15px 20px;
    font-size: 20px;
}
.accent, input[type='submit'].accent {
    background: #56CBF9;
}
input[type='range'] {
    filter: brightness(131%) hue-rotate(295deg);
}
select {
    font: inherit;
    display: block;
    border: none;
    background: #1DB954;
    color: white;
    border-radius: 5px;
    padding: 10px 12px;
    margin-top: 3px;
    border-radius: 50px;
    cursor: pointer;
}
input[type='text'], input[type='number'], input[type='email'] {
    display: block;
    font: inherit;
    padding: 8px 0;
    color: white;
}
.slider input,
.slider span {
    vertical-align: middle;
}
.hue-range-slider {
    position: relative;
    height: 6px;
    width: 100%;
    border-radius: 10px;
    margin: 10px 0 15px;
    cursor: pointer;
}
.handle {
    position: absolute;
    top: -5px;
    width: 15px;
    height: 15px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transform: translateX(-50%);
    cursor: grab;
}
.handle:active {
    cursor: grabbing;
}
.lightness-range-slider {
    position: relative;
    height: 6px;
    width: 100%;
    border-radius: 10px;
    margin: 10px 0 15px;
    cursor: pointer;
}
input[type='submit'] {
    display: block;
    padding: 12px 15px;
    font-weight: bold;
}
input[type='submit'][disabled] {
    opacity: 0.5;
    cursor: default;
}


.graph {
    width: 100vw;
    background-size: contain;
    background-repeat: no-repeat;
}
.placeholder {
    background: #1DB954;
    width: 100%;
    aspect-ratio: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: hueshift 30s infinite linear;
}
.placeholder.loading {
    animation-duration: 3s;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loader.center {
    height: 80vh;
}
.bars {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-bottom: 10px;
}

.bar {
    height: 50px;
    width: 7px;
    margin: 0 4px;
    border-radius: 10px;
    background-color: white;
    animation: loading 0.8s infinite;
}

.bar:nth-child(2) { animation-delay: -0.1s; }
.bar:nth-child(3) { animation-delay: -0.2s; }
.bar:nth-child(4) { animation-delay: -0.3s; }
.bar:nth-child(5) { animation-delay: -0.4s; }
.bar:nth-child(6) { animation-delay: -0.5s; }
.bar:nth-child(7) { animation-delay: -0.6s; }
.bar:nth-child(8) { animation-delay: -0.7s; }
@keyframes loading {
    0% {
        height: 5px;
    }
    50% {
        height: 50px;
    }
    100%{
        height: 5px;
    }
}

@media (max-width: 600px) {
    header {
        flex-direction: column;
    }
    #logo {
        margin-bottom: 10px;
    }
    .exampleRow img {
        height: 30vh;
    }
}
